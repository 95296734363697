const NotFound = () => {
  return (
    <div
      className="flex items-center justify-center m-0"
    >

      <h1 className="text-2xl text-center font-bold animate-scaling  p-5 rounded-xl  text-black"> 404 &nbsp; &nbsp; Page Not Found</h1>

    </div>
  );
};

export default NotFound;