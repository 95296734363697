
const Footer = () => {
  return (
    <>
      <footer >
        <p className="font-bold">
          Designed & Developed & Deployed by <a href="https://aly-h.com">Ali Hussein</a> &copy; 2024 All Rights Reserved
        </p>
      </footer>
    </>
  )
};

export default Footer;